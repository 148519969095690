













































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import { Utilisateur, Profil } from '@/models';
import IdentityForm from '@/components/FormsUtilisateur/IdentityForm.vue';
import SocieteForm from '@/components/FormsUtilisateur/SocieteForm.vue';
import ResetPasswordForm from '@/components/FormsUtilisateur/ResetPasswordForm.vue';
import EmailModifForm from '@/components/FormsUtilisateur/EmailModifForm.vue';
import DroitsAccesForm from '@/components/FormsUtilisateur/DroitsAccesForm.vue';
import ValidationAction from '@/components/ValidationAction.vue';
import ModifierUtilisateur from '@/components/FormsUtilisateur/ModifierUtilisateur.vue';
import FormValidator from './Form.Validator';
import { UtilisateurIdentity, UtilisateurEmail, UtilisateurStatus, UtilisateurDroitsAcces } from '@/components/FormsUtilisateur/models/';
import { Societe } from '@/models/Societe.model';
import { ApiService } from '@/services/base/ApiService';
import { ApiHelper } from '@/services/ApiHelper';
import { UtilisateurApiHelper } from '@/services/UtilisateurApiHelper';
import { UtilisateurMotDePasse } from '@/views/Compte/Profil/models';
import { MapUtilisateur } from '@/components/FormsUtilisateur/helpers/MapUtilisateur';
import { UtilisateurStoreMethods } from '@/store/modules/utilisateur/UtilisateurStore';
import { Getter, Mutation, Action } from 'vuex-class';
import { TypeProfilUtilisateur } from '../../shared/enums/TypeProfilUtilisateur.enum';
import { Profils } from '@/shared/enums';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { DateHelper } from '@/shared/helpers';

@Component({
    name: 'ContainerEditForm',
    components: {
        IdentityForm,
        SocieteForm,
        ResetPasswordForm,
        EmailModifForm,
        DroitsAccesForm,
        ValidationAction,
        ModifierUtilisateur,
        CeeDatePicker,
    },
})
export default class ContainerForm extends Mixins(FormValidator) {

    // Permet de faire en sorte que le template ait accès à l'enum.
    public Profils = Profils;

    public $refs!: {
        formSociete: HTMLFormElement;
        formPassword: HTMLFormElement;
        formIdentity: HTMLFormElement;
        formEmail: HTMLFormElement;
        formDroitsAcces: HTMLFormElement;
        desactivationCompteForm: HTMLFormElement;
    };

    @Prop({ default: 0 })
    public readonly userId!: number;

    @Getter(UtilisateurStoreMethods.UTILISATEUR)
    private importedUser: Utilisateur;

    @Mutation(UtilisateurStoreMethods.SET_UTILISATEUR)
    private setUser: (user: Utilisateur) => void;

    @Mutation(UtilisateurStoreMethods.SET_UTILISATEUR_BY_PROPERTIES)
    private setPropertyUtilisateur: (property: object) => void;

    @Action(UtilisateurStoreMethods.CHANGE_TYPE_PROFIL)
    private setTypeProfil: (type: TypeProfilUtilisateur) => void;

    /** Profils disponibles, avec leur libellés */
    @Getter(UtilisateurStoreMethods.PROFILS)
    public profils: Profil[];

    /**
     * Persistence de la liste des profils sélectionnables.
     */
    @Mutation(UtilisateurStoreMethods.SET_PROFILS)
    private setProfils: (profils: Profil[]) => void;

    private userPassword = new UtilisateurMotDePasse();

    private userEmail: UtilisateurEmail = new UtilisateurEmail();


    private userIdentity: UtilisateurIdentity = new UtilisateurIdentity();

    private originalUserIdentity: UtilisateurIdentity = new UtilisateurIdentity();

    private userSociete: Societe[] = new Array<Societe>();

    private originalUserSociete: Societe[] = new Array<Societe>();

    private desactivationCompteFeature: {
        dialog: boolean,
        date: string,
         time:string,
        isScheduleTime: boolean,
    } = { 
        dialog: false,
        date: '',
        time:'',
        isScheduleTime: false,
    };

    /**
     * L'objet qui définit l'état des droits d'accès utilisateur modifiable via l'interface graphique.
     * Utilisé en comparaison avec l'original pour verifier si un changement d'état a eu lieu.
     * @memberof ContainerForm
     */
    private droitsAccesUtilisateur: UtilisateurDroitsAcces = new UtilisateurDroitsAcces();

    /**
     * Désactive les différents boutons lors de l'appel au serveur.
     */
    public loading = false;

    public mounted() {

        ApiHelper.getAll<Profil>('profil/obtenirTous').then(response => {
            this.setProfils(response);
        });

        if (this.userId > 0) {
            this.getUserById(this.userId).then(userResponse => {
                this.setUser(userResponse);
                this.originalUserIdentity = MapUtilisateur.toIdentity(this.importedUser);
                this.originalUserIdentity.userId = parseInt(this.$route.params.id, 10);
                this.originalUserSociete = MapUtilisateur.toSociete(this.importedUser);
                this.droitsAccesUtilisateur = MapUtilisateur.toDroitsAcces(this.importedUser);
            });
        }
    }

    public get btnStatusColor(): string {
        return this.importedUser.isActive && !this.importedUser.datePlanifieDesactivation  ? 'normal' : 'primary';
    }

    public get textStatus(): string {
        return this.importedUser.isActive && !this.importedUser.datePlanifieDesactivation ? 'Désactiver le compte' : 'Activer le compte';
    }

    public actionEnableDisableUser() {
        return this.importedUser.isActive && !this.importedUser.datePlanifieDesactivation  ? 
            (() => {this.desactivationCompteFeature.dialog=true})() : this.changerStatus();
    }

    private get aujourdhui(): string {
        return DateHelper.todayIso();
    }

    /**
     * Récupère le texte d'en-tête pour cet utilisateur, de la forme - Prénom Nom [Profil].
     */
    public get enteteUtilisateur(): string {
        return (this.importedUser && this.importedUser.profilUtilisateur)
            ? this.originalUserIdentity.lastName + ' ' + this.originalUserIdentity.firstName + ' ' + this.getLibelleProfil(this.importedUser.profilUtilisateur)
            : 'Chargement...';
    }

    public getUserById(id: number): Promise<Utilisateur> {
        const utilisateurAdminFormEdit = new ApiService<Utilisateur>('utilisateur/obtenir');
        return new Promise<Utilisateur>((resolve, reject) => {
            return utilisateurAdminFormEdit.get(id).then(response => {
                resolve(response.data.data);
            })
                .catch((error: { response: any }) => {
                    reject(error.response);
                });
        });
    }

    /**
     * Rafraîchit l'email avec la valeur fournie.
     * @param value Nouvelle adresse mail.
     */
    public refreshEmail(value: UtilisateurEmail): void {
        this.userEmail = value;
    }

    /**
     * Annulation de modifications de l'utilisateur et retour a l'onglet de gestion d'utilisateurs.
     * @memberof ContainerForm
     */
    public resetModificationUtilisateur(): void {
        (this.$router as any).push({ name: 'gestion-utilisateurs' });
    }

    public scheduleDesactivationUser() {
        const valid = (this.$refs.desactivationCompteForm || {validate: () => true}).validate();
        if(valid) {
            if(DateHelper.equalsToday(this.desactivationCompteFeature.date)) {
                this.changerStatus().then(() => {
                    this.desactivationCompteFeature.dialog = false;
                });
            } else {
                UtilisateurApiHelper.dispatchDefinirDateDesactivation({model: {
                    id: this.importedUser.id,
                    datePlanifieDesactivation: this.desactivationCompteFeature.date,
                }}).finally(() => {
                    this.desactivationCompteFeature.dialog = false;
                    this.setPropertyUtilisateur({ datePlanifieDesactivation: this.desactivationCompteFeature.date });
                });
            }
        }
    }

    public updateIdentity(params: { observerIdentity: InstanceType<typeof ValidationObserver> }): void {
        const { observerIdentity } = params;
        observerIdentity.validate().then(() => {
            this.loading = true;
            this.userIdentity = MapUtilisateur.toIdentity(this.importedUser);
            UtilisateurApiHelper.dispatchModifierUtilisateurAdminIdentite({ utilisateurIdentite: { ...this.userIdentity } })
                .finally(() => {
                    this.loading = false;
                });
        });
    }

    /**
     * Met à jour les sociétés de l'utilisateur après validation du formulaire.
     * @param params Objet contenant le formulaire à valider.
     */
    public updateSociete(params: { refFormSociete: HTMLFormElement }): void {
        const { refFormSociete } = params;
        if (refFormSociete.validate()) {
            this.loading = true;
            this.userSociete = MapUtilisateur.toSociete(this.importedUser);
            UtilisateurApiHelper.dispatchModifierUtilisateurAdminEntreprise({ utilisateurSocietes: this.userSociete })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    /**
     * Update le mail.
     * @param params
     */
    public updateEmail(params: { observerEmail: InstanceType<typeof ValidationObserver> }): void {
        const { observerEmail } = params;
        observerEmail.validate().then(() => {
            this.loading = true;
            UtilisateurApiHelper.dispatchModifierUtilisateurAdminEmail({ utilisateurEmail: { ...this.userEmail } })
                .finally(() => {
                    this.loading = false;
                });
        });
    }

    public sendEmailResetPassword(): Promise<object> {
        this.loading = true;
        const userObject: object = { Email: this.importedUser.email };
        const passwordResetApi = new ApiService<object>('utilisateur/forgotPassword');
        return new Promise<object>((resolve, reject) => {
            return passwordResetApi.post(userObject)
                .then(response => {
                    resolve(response.data.data);
                })
                .catch((error: { response: any }) => {
                    reject(error.response);
                })
                .finally(() => this.loading = false);
        });
    }

    /**
     * Mets a jour  les droits d'accès utilisateur dans la base de données.
     * @memberof ContainerForm
     */
    public updateDroitsAcces(): void {
        this.loading = true;
        const droitsAcces = MapUtilisateur.toDroitsAcces(this.importedUser);
        const utilisateurApiService = new ApiService<UtilisateurDroitsAcces>('utilisateur/modifierDroitsAcces');
        utilisateurApiService.put(droitsAcces)
            .finally(() => {
                this.loading = false;
            });
    }

    /**
     * Renvoie le lien d'activation du compte à l'utilisateur si celui-ci n'est pas activé.
     */
    public renvoyerLienActivation(): Promise<void> {
        this.loading = true;
        const utilisateurApiService = new ApiService('utilisateur/renvoyerLienActivation/' + this.importedUser.id);
        return new Promise<void>(() => {
            return utilisateurApiService.post(null).finally(() => this.loading = false);
        });
    }

    public changerStatus(): Promise<UtilisateurStatus> {
        this.loading = true;
        const userStatus: UtilisateurStatus = { id: this.importedUser.id, isDeleted: false, isActive: !this.importedUser.isActive, EmailConfirmed: !this.importedUser.EmailConfirmed };
        const utilisateurApiService = new ApiService<UtilisateurStatus>('utilisateur/changerStatus');
        return new Promise<UtilisateurStatus>((resolve, reject) => {
            return utilisateurApiService.put(userStatus)
                .then(response => {
                    this.getUserById(this.userId).then(userResponse => {
                        this.setUser(userResponse);
                    });
                    resolve(response.data.data);
                })
                .catch((error: { response: any }) => {
                    reject(error.response);
                })
                .finally(() => this.loading = false);
        });
    }

    public destroyed() {
        // Reset de l'utilisateur si jamais on change d'utilisateur ensuite.
        this.setUser(new Utilisateur());
        this.setTypeProfil(TypeProfilUtilisateur.None);
    }

    /**
     * Retourne le libellé du profil.
     * @param codeProfil Code du profil à afficher.
     * @returns Description du libellé si elle existe.
     */
    private getLibelleProfil(codeProfil: string): string {
        return (codeProfil && this.profils)
            ? '[' + this.profils.filter(p => p.name === codeProfil)[0].description + ']'
            : '';
    }
}
