import { Component, Vue, Prop } from 'vue-property-decorator';
import ContainerForm from '@/components/FormsUtilisateur/ContainerForm.vue';
import ContainerEditForm from '@/components/FormsUtilisateur/ContainerEditForm.vue';

import template from './ModifierUtilisateur.Template.vue';

@Component({
  ...template,
  name: 'ModifierUtilisateur',
    components: {
        ContainerForm,
        ContainerEditForm
    },
})
export default class ModifierUtilisateur extends Vue {
    @Prop({ default: 0 })
    public readonly userId!: number;
}

